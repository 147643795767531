import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { USERS_COLLECTION } from "constants/CollectionConstants";
import { doc, getDoc } from "firebase/firestore";
import { db } from "auth/FirebaseAuth";
import { AUTH_UID } from "constants/AuthConstant";

export const initialState = {
  loading: false,
  userProfile: null,
  error: null,
  userUid: localStorage.getItem(AUTH_UID) || null,
};

export const fetchUserProfile = createAsyncThunk(
  "userProfile/fetchUserProfile",
  async (userId, { rejectWithValue }) => {
    try {
      const userDoc = await getDoc(doc(db, USERS_COLLECTION, userId));
      if (userDoc.exists()) {
        return userDoc.data();
      } else {
        return rejectWithValue("User not found.");
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const userProfileSlice = createSlice({
  name: "userProfile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.userProfile = action.payload;
      })
      .addCase(fetchUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default userProfileSlice.reducer;
