export const USERS_COLLECTION = "users";

export const USER_COLLECTION_FIELDS = {
  EMAIL: "email",
  NAME: "name",
  PHOTO_URL: "photoUrl",
  UID: "uid",
  CREATED_TIME: "createdTime",
  PHONE_NUMBER: "phoneNumber",
  ADDRESS: "address",
  ADDRESS_SUPPLEMENT: "addressSupplement",
  CITY: "city",
  LAND: "land",
  POSTAL_CODE: "postalCode",
  TOTAL_CVS_ANALYSED: "totalCvsAnalysed",
  CV_CREDITS: "cvCredits",
  USED_CREDITS: "usedCredits",
  AVAILABLE_CREDITS: "availableCredits",
  LINKEDIN_URL: "linkedinUrl",
  GITHUB_URL: "githubUrl",
};

export const CV_ANALYSIS_COLLECTION = "cv_analysis";
