// Your web app's Firebase configuration
const FirebaseConfig = {
  apiKey: "AIzaSyCPnqHIoWJ9a0e8qgI9C-_M9rCwx9bXHVY",
  authDomain: "cv-booster-4f502.firebaseapp.com",
  projectId: "cv-booster-4f502",
  storageBucket: "cv-booster-4f502.appspot.com",
  messagingSenderId: "1042350796930",
  appId: "1:1042350796930:web:2ad4f69997e0b4905f849b",
};

export default FirebaseConfig;
